import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FancyTitle from '../components/fancy-title';
import SplitPane from '../components/split-pane';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import LogoWall from '../components/logo_wall';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/virtual';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';

import * as icons from '../icons/icon-pack';
import ReferencesSelection from '../components/references_selection';
import ScrollSlider from '../components/scroll-slider';
import PipedriveForm from '../components/pipedrive-form';

function ServicesDetailTemplate({ data }) {
  library.add({ ...icons });

  const service = data.servicesYaml;
  const services = data.allServicesYaml.nodes;
  const samples = React.useMemo(() => {
    const { sample } = service;
    if (service.additional_samples && sample) {
      return [sample, ...service.additional_samples];
    } if (sample) {
      return [sample];
    }
    return [];
  }, [service]);
  const image = getImage(service.image);
  const stepsImage = getImage(service.stepsimage);

  const [theme, setTheme] = useState('dark');

  const handleThemeChange = useCallback((event) => {
    setTheme(event.theme);
  }, []);

  useEffect(() => {
    window.addEventListener('handleThemeChange', handleThemeChange);
    return () => {
      window.removeEventListener('handleThemeChange', handleThemeChange);
    };
  }, [handleThemeChange]);

  let svgImage;
  if (service[`image_${theme}`]) {
    svgImage = service[`image_${theme}`]?.extension === 'svg' && service[`image_${theme}`]?.publicURL;
  } else {
    svgImage = service.image?.extension === 'svg' && service.image.publicURL;
  }

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: service?.meta_title ? service?.meta_title?.replace(/&shy;/gi, '') : service.title.replace(/&shy;/gi, ''), fullWidth: true });
        return (
          <>
            <Seo title={service?.meta_title ? service?.meta_title?.replace(/&shy;/gi, '') : service.title.replace(/&shy;/gi, '')} description={service?.meta_description ? service?.meta_description?.replace(/&shy;/gi, '') : service.description.replace(/&shy;/gi, '')} />
            <div className="px-4 lg:px-16 pb-12">
              <SplitPane
                className="py-12 lg:h-[100vh] items-center gap-8 xl:gap-16"
                leftWidth="xl:w-2/5"
                rightWidth="xl:w-3/5"
                left={(
                  <div className="w-full">
                    <FancyTitle
                      text={service.title}
                      tag="h1"
                      marginBottom="mb-4 lg:mb-20"
                    />
                    { !svgImage && image
                      && (
                      <GatsbyImage
                        image={image}
                        alt={service.title}
                        className="w-full lg:hidden my-12 lg:my-24"
                      />
                      )}
                    {svgImage
                      && (
                      <div className="px-10">
                        <img
                          src={svgImage}
                          alt={service.title}
                          width="126"
                          height="26"
                          className="w-full lg:hidden my-12 lg:my-24"
                          loading="lazy"
                        />
                      </div>
                      )}
                    <p className="mt-4 lg:mt-0 leading-6 lg:block" dangerouslySetInnerHTML={{ __html: service.description }} />
                    {(service?.url && service?.link_text) && (
                      <Link
                        href={service.url}
                        target="_blank"
                        rel="noreferrer"
                        className="button-primary inline-block self-start mt-4"
                      >
                        {service.link_text}
                      </Link>

                    )}
                  </div>
                )}
                right={(
                  <div>
                    { !svgImage && image
                      && (
                      <GatsbyImage
                        image={image}
                        alt={service.title}
                        className="w-full hidden lg:block"
                      />
                      )}
                    {svgImage
                      && (
                      <div className="px-10">
                        <img
                          src={svgImage}
                          alt={service.title}
                          width="126"
                          height="26"
                          className="w-full object-contain hidden lg:block"
                          loading="lazy"
                        />
                      </div>
                      )}
                  </div>
                )}
              />

              { service?.logo_wall
                && (
                <>
                  <FancyTitle
                    text={service.logo_wall.title}
                    tag="h2"
                    className="mt-16 lg:mt-0"
                  />
                  <LogoWall data={service.logo_wall} />
                </>
                )}

              { service.overview_content
                && service.overview_content.title
                && service.overview_content.description
                && service.overview_content.cta
                && service.overview_content.cta.link
                && service.overview_content.cta.label
                && service.overview_content.image
                && (
                  <SplitPane
                    className="mb-16 gap-8 xl:gap-16 flex-col-reverse lg:flex-row"
                    leftWidth="lg:w-1/2"
                    rightWidth="lg:w-1/2"
                    right={(
                      <div className="w-full">
                        <FancyTitle
                          tag="h2"
                          text={service.overview_content.title}
                          subtitle={service.overview_content.description}
                          className="overview_content mb-4 lg:mb-20"
                        />
                        <Link
                          to={service.overview_content.cta.link}
                          className="button-primary self-start mt-4 hidden lg:inline-block"
                          target="_blank"
                        >
                          { service.overview_content.cta.label }
                        </Link>
                      </div>
                    )}
                    left={(
                      <div className="flex flex-col">
                        <Link to={service.overview_content.cta.link} target="_blank">
                          <div>
                            <GatsbyImage
                              image={getImage(service.overview_content.image)}
                              alt={service.overview_content.title}
                              className="w-full"
                            />
                          </div>
                        </Link>
                        <Link
                          to={service.overview_content.cta.link}
                          className="button-primary inline-block self-start mt-4 lg:hidden"
                          target="_blank"
                        >
                          { service.overview_content.cta.label }
                        </Link>
                      </div>
                    )}
                  />
                )}

              { service.subtitle || service.subdescription ? (
                <div className="py-6">
                  <FancyTitle text={service.subtitle} tag="h2" />
                  <p className="leading-6" dangerouslySetInnerHTML={{ __html: service.subdescription }} />
                </div>
              ) : ''}

              {(service.uspstitle || service.uspsdescription) && (
                <div className="py-6 pt-24">
                  {service.uspstitle && (
                    <FancyTitle text={service.uspstitle} tag="h2" />
                  )}

                  {service.uspsdescription && (
                    <p className="leading-6" dangerouslySetInnerHTML={{ __html: service.uspsdescription }} />
                  )}
                </div>
              )}

              { service?.usps && (
              <div className="pb-24 pt-6 grid grid-cols-1 lg:grid-cols-3 gap-20 lg:gap-4 xl:gap-20 highlighted-wrapper">
                { service.usps?.map((usp, i = 1) => (
                  <div className="pt-5 pb-10 px-5 border-solid border-latori-green-dark border-2 rounded" key={`t-${usp.title}`}>
                    <div className="flex items-center dark w-full">
                      {/* <div className="inline-flex justify-center items-center mr-6">
                        <FontAwesomeIcon
                          icon={icon({
                            prefix: usp.icon !== 'shopify' ? 'far' : 'fab',
                            iconName: usp.icon
                          })}
                          size="xl"
                          className="text-latori-green-dark"
                        />
                      </div> */}
                      <style type="text/css">
                        {`
                        .highlighted-wrapper > div:nth-child(${i + 1}) .green-background-highlight::before {
                          top: ${usp.backdrop_y}%;
                          right: ${usp.backdrop_x}%;
                        }
                        `}

                      </style>
                      <FancyTitle
                        text={usp.title}
                        marginBottom=""
                        fontSize="text-2xl"
                        className="fancy-title-small w-[calc(100%+16px)] p-[10px] hyphens-auto
                        bg-background-dark text-white green-background-highlight mx-[-10px]"
                        tag="h3"
                      />
                    </div>
                    <p className=" leading-6 pt-6" dangerouslySetInnerHTML={{ __html: usp.description }} />
                  </div>
                ))}
              </div>
              )}
              {service.steps?.title && (
                <>
                  <FancyTitle text={service.steps.title} tag="h2" />
                  {service.steps?.description && (
                    <p className="leading-6" dangerouslySetInnerHTML={{ __html: service.steps.description }} />
                  )}
                </>
              )}
              { (service.steps && service.steps.items) && (
                <div className="pb-6 px-4">
                  <SplitPane
                    items="start"
                    className="pb-24 items-center"
                    leftWidth="xl:w-3/5"
                    rightWidth="xl:w-2/5 -order-1 md:order-2"
                    left={(
                      <div className="py-3 w-full">
                        { service?.steps?.items?.map((step, i) => (
                          <div className="flex py-4" key={`d-${step.title}`}>
                            <div className="text-center pr-6">
                              <div className="rounded-full !w-[60px] !h-[60px] flex justify-center items-center bg-latori-green-dark text-white">
                                <span className="text-2xl">{String(i + 1).padStart(2, '0')}</span>
                              </div>
                              <div className="mt-6 mx-auto bg-latori-green w-[1px] h-[calc(100%-75px)]" />
                            </div>
                            <div className="pt-2 pr-2 md:pr-12 min-h-[300px]">
                              <FancyTitle
                                text={step.title}
                                fontSize="text-lg"
                                marginBottom="0"
                                className="fancy-title-small"
                                tag="h3"
                              />
                              <p className="pt-4 leading-6" dangerouslySetInnerHTML={{ __html: step.description }} />
                            </div>
                          </div>
                        )) }
                      </div>
                    )}
                    right={
                      service.stepsimage && service.stepsimage?.name !== 'placeholder' && stepsImage && (
                        <div>
                          <GatsbyImage
                            image={stepsImage}
                            alt={service.title}
                            className="w-full"
                          />
                        </div>
                      )
                    }
                  />
                </div>
              )}

              {samples.length > 0 && (
                <ScrollSlider items={samples} subheadlineMobile />
              )}

              { service.references ? (
                <div className="px-4 pt-12 pb-8 lg:pb-32">
                  <ReferencesSelection
                    title={service.references?.title ? service.references.title : 'Weitere Beispiele'}
                    showMore={false}
                    referencesToShow={Object.values(service.references?.items)}
                  />
                </div>
              ) : '' }

              <div className="px-4 py-12">
                <PipedriveForm
                  title="Lassen Sie uns sprechen!"
                  subtitle="Beratung durch Shopify-Experten!"
                />
              </div>

              <div className="px-4 pt-12 pb-32">
                <FancyTitle
                  text="Weitere Leistungen"
                  textAlign="text-left md:text-center"
                  tag="h2"
                />

                <div className="grid gap-4 grid-cols-2 lg:grid-cols-4 pt-2">
                  {
                    services
                      .filter((e) => e.slug !== service.slug).slice(0, 4)
                      .map((node, index) => (
                        <Link
                          to={`/services/${node.slug}`}
                          className={`${index > 4 ? 'hidden lg:flex' : 'flex'} relative gap-4 flex-col justify-center items-center h-48 bg-green-800 hover:bg-green-900`}
                          key={node.title}
                        >
                          {node.icon
                            && (
                            <FontAwesomeIcon
                              icon={icon({ prefix: 'far', iconName: node.icon })}
                              size="2x"
                              className="text-white"
                            />
                            )}
                          <h4 className="text-lg w-full text-center break-words px-2 text-white" dangerouslySetInnerHTML={{ __html: node.name }} />
                        </Link>
                      ))
                  }
                </div>
              </div>

              { (service.seo_title || service.seo_text) && (
                <>
                  {service.seo_title && (
                    <FancyTitle
                      text={service.seo_title}
                      tag="h3"
                      marginBottom="mb-10"
                      fontSize="text-3xl text-lg"
                    />
                  )}
                  {service.seo_text && (
                    <p className={`leading-6 mb-10 service-list ${service.seo_title_2 === '' && service.seo_text_2 === '' ? 'xl:columns-2' : ''}`} dangerouslySetInnerHTML={{ __html: service.seo_text }} />
                  )}
                </>
              )}
              { (service.seo_title_2 || service.seo_text_2) && (
                <>
                  {service.seo_title_2 && (
                    <FancyTitle
                      text={service.seo_title_2}
                      tag="h3"
                      marginBottom="mb-10"
                      fontSize="text-3xl text-lg"
                    />
                  )}
                  {service.seo_text_2 && (
                    <p className="leading-6 mb-10" dangerouslySetInnerHTML={{ __html: service.seo_text_2 }} />
                  )}
                </>
              )}
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default ServicesDetailTemplate;

export const servicesDetailQuery = graphql`
  query ServicesDetailQuery(
    $id: String!
  ) {
    servicesYaml(id: { eq: $id }) {
      name
      excerpt
      title
      description
      meta_title
      meta_description
      slug
      icon
      url
      link_text
      seo_title
      seo_text
      seo_title_2
      seo_text_2
      image {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image_light {
        extension
        publicURL
      }
      image_dark {
        extension
        publicURL
      }
      link
      subtitle
      subdescription
      overview_content {
        title
        description
        cta {
          link
          label
        }
        image {
          childImageSharp {
            gatsbyImageData(
              width: 1024,
              placeholder: BLURRED,
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      uspstitle
      uspsdescription
      usps {
        title
        description
        icon
        backdrop_x
        backdrop_y
      }
      steps {
        title
        description
        items {
          title
          description
          icon
        }
        image {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      sample {
        title
        description
        background_color
        link_to
        id
        image {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      additional_samples {
        title
        description
        background_color
        id
        image {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        link_to
      }
      logo_wall {
        title
        items {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 480
                placeholder: BLURRED
                breakpoints: [320, 480, 560, 768, 1024, 1280, 1440, 1920]
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          logo {
            publicURL
            extension
          }
          link
          logoWidth
          logoHeight
        }
      }
      references {
        title
        items {
          ref_1
          ref_2
          ref_3
          ref_4
        }
      }
    }
    allServicesYaml(limit: 5) {
      nodes {
        title
        name
        description
        slug
        icon
      }
    }
  }
`;
