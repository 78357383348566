import * as React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FancyTitle from './fancy-title';
import * as icons from '../icons/icon-pack';

function ReferencesSelection({
  title = 'Referenzen', titlePosition = 'text-center', text, showMore = true, referencesToShow,
}) {
  library.add({ ...icons });

  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(references)/"}}) {
          nodes {
            frontmatter {
              title
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 480
                    placeholder: BLURRED
                    breakpoints: [320, 480, 560, 768, 1024, 1280, 1440, 1920]
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              link
              logo {
                publicURL
                extension
              }
              logoWidth
              logoHeight
            }
          }
        }
      }
    `,
  );

  let references = data.allMarkdownRemark.nodes;

  if (referencesToShow) {
    references = references.filter((reference) => {
      const slug = reference.frontmatter.link;
      const slugIndex = referencesToShow.findIndex((item) => {
        const _item = item.replace('/references/', '');
        return _item === slug;
      });
      return slugIndex > -1;
    });
  }

  // references = references.sort((a,b) => a.frontmatter.title.localeCompare(b.frontmatter.title))

  return (
    <div>
      <FancyTitle text={title} className={`${text ? 'my-0' : 'my-10'} ${titlePosition}`} tag="h2" />
      {text && (
        <div className="hidden lg:block mb-24 text-xl">{text}</div>
      )}
      <div className="grid gap-4 grid-cols-1 s:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
        {references && references.map((edge, index) => {
          const reference = edge.frontmatter;
          const image = getImage(reference.image);
          return (
            <div className={`relative h-full overflow-hidden${index > 4 && showMore ? ' hidden lg:flex' : ' flex'}`} key={reference.title}>
              {image && (
                <GatsbyImage
                  image={image}
                  className="w-full"
                  alt={reference.title}
                />
              )}
              <Link to={`/references/${reference.link}`} className="absolute w-full h-full flex">
                <div className="absolute w-full h-full bg-green-800 z-10 opacity-75 hover:bg-slate-700 hover:opacity-20 transition" />
                <div className="m-auto z-20 pointer-events-none">
                  {
                    reference.logo?.publicURL
                      ? (<img src={reference.logo.publicURL} width={reference.logoWidth} height={reference.logoHeight} className="max-h-8 s:max-h-12 xl:max-h-16 h-full max-w-[100px] s:max-w-[150px] xl:max-w-[175px] w-full object-contain" alt={reference.title} loading="lazy" />)
                      : (<h4>{reference.title}</h4>)
                  }
                </div>
              </Link>
            </div>
          );
        })}
        {showMore && (
          <Link to="/references" className="flex bg-green-800 hover:bg-green-900 text-white group aspect-[20/9]">
            <div className="flex m-auto items-center group-hover:scale-105 transition-transform">
              <span className="mr-2">Mehr</span>
              <FontAwesomeIcon icon={icon({ prefix: 'far', iconName: 'arrow-right' })} size="2x" />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

export default ReferencesSelection;
