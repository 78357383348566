import * as React from 'react';
import { createElement } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function LogoWall({ data }) {
  const logos = data;

  const scrollToElement = (e, id) => {
    if (!id.includes('#')) return;
    e.preventDefault();
    document.querySelector(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="grid gap-4 grid-cols-1 s:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 pb-24">
      {logos && logos.items.map((item) => {
        const logo = item;
        const image = getImage(logo.image);
        const cardContent = (
          <>
            <div className="absolute w-full h-full bg-green-800 z-10 opacity-75 hover:bg-slate-700 hover:opacity-20 transition" />
            <div className="m-auto z-20 pointer-events-none">
              {
                  createElement(
                    'h2',
                    { className: 'sr-only' },
                    logo.title,
                  )
                }
              {
                  logo.logo?.publicURL && (
                    <img
                      src={logo.logo.publicURL}
                      width={logo.logoWidth}
                      height={logo.logoHeight}
                      className="max-h-8 s:max-h-12 xl:max-h-16 h-full max-w-[100px] s:max-w-[150px] xl:max-w-[175px] w-96 object-contain"
                      alt={logo.title}
                      loading="lazy"
                    />
                  )
                }
            </div>
          </>
        );
        return (
          <div
            title={logo.title}
            className="relative h-full overflow-hidden"
            key={logo.title}
          >
            {image && (
            <GatsbyImage
              image={image}
              className="w-full h-full aspect-[369.25/156.92]"
              alt={logo.title}
            />
            )}
            {logo.link
              ? (
                <Link
                  to={logo.link}
                  onClick={(e) => {
                    scrollToElement(e, logo.link);
                  }}
                  className="absolute w-full h-full flex top-0"
                >
                  {cardContent}
                </Link>
              )
              : <div className="absolute w-full h-full flex">{cardContent}</div>}
          </div>
        );
      })}
    </div>
  );
}

export default LogoWall;